import React, { useMemo } from "react";
import { LogoImage, useAppSettings } from "../../services/app-setting.hook";
import "react-quill/dist/quill.snow.css";
import "./index.scss";
import i18n from "../../i18n";

export const AboutUsComponent = () => {
  const { settings } = useAppSettings();

  const lang = i18n.language === 'fr' ? 'fr' : 'en';
  return (
    <div className="pageContainer aboutUs">
      <div className="pageHeader">
        <div className="pageHeaderLogoWrap">
          <LogoImage src="whiteLogoBig" className="aboutUsLogo" alt="" />
          <LogoImage src="aboutUsHeader" className="aboutUsTitle" alt="" />
        </div>
      </div>
      <div className="pageContent">
        <div
          className="leftSection"
          style={{
            maxWidth: 475,
          }}
          dangerouslySetInnerHTML={{ __html: settings.aboutUsText?.[lang] }}
        ></div>

        <div className="rightSection">
          <img
            src="/assets/images/RadiologyResidentConsultation.jpg"
            className="topLeft"
            alt=""
          />
          <img
            src="/assets/images/LadyDoctor.jpg"
            className="topRight"
            alt=""
          />
          <img
            src="/assets/images/MaleDoctorTexting.jpg"
            className="bottomLeft"
            alt=""
          />
          <img
            src="/assets/images/MultipleDoctorVideoChat.jpg"
            className="bottomRight"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
